import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledToolsBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47]
  })
)

export const styledTools = css`
  ${styledToolsBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    width: [328, '100%', 680, 824],
    marginLeft: [0, 0, 200, 210],
    marginRight: [0, 0, 200, 238],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9]
  })
)

export const PHeaderBps = css(
  mq({
    width: [328, '100%', 680, 800],
    marginLeft: [0, 0, 200, 210],
    marginRight: [0, 0, 200, 238],
    textAlign: ['left']
  })
)

export const H5HeaderBps = css(
  mq({
    width: [328, '100%', 680, 824],
    marginLeft: [0, 0, 200, 210],
    marginRight: [0, 0, 200, 238],
    marginBottom: [16],
    textAlign: ['left']
  })
)

export const H5Bps = css(
  mq({
    width: [328, '100%', 680, 824],
    margin:['0 auto'],
    marginBottom: [24],
    textAlign: ['left', 'center']
  })
)

export const PBps = css(
  mq({
    width: [328, '100%', 680, 700],
    margin:['0 auto'],
    textAlign: ['left', 'center'],
    marginTop: [30],
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left']
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
`
export const meuIp = css`
  display: flex;
  text-align: center;
  h5 {
    ${H5Bps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PBps};
  }
`
export const btnMeuIp = css`
  display: flex;
  width: 328px;
  background-color: #f9fafa;
  color: rgba(0,0,0,0.87);
  height: 56px;
  border-radius: 30px;
  font-size: 15.7px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
`