import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from 'gatsby-image' */
import { graphql, navigate }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Hr from '../components/Hr'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import MeuIp from '../components/MeuIp'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import MuiField from '../components/MuiField'
import MuiSelect from '../components/Dropdown'
import { 
  styledTools,
  header,
  meuIp,
  btnMeuIp
} from '../components/styles/Tools.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledTools}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            pb={50}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>Ferramentas</span><br />
                Teste sua velocidade de conexão com nossos data centers globais.
              </H4>
              <Paragraph>Acompanhe o roteamento e comportamento da rede que se origina de nossas redes remotas e teste sua velocidade de conexão com nossos data centers globais.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section
        style={{
          backgroundColor: '#f9fafa',
          padding: '50px 0'
        }}
      >
        <Container>
          <Row
            flexDirection={['column']}
            css={header}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <H5>Ferramentas de Rede</H5>
              <Paragraph>Nós estamos comprometidos em fornecer aos nossos clientes todo o apoio necessário para gerenciar de maneira rápida e fácil todos os aspectos do seu servidor. Por isso, desenvolvemos ferramentas criadas especialmente para ajudar você a gerenciar seus servidores.</Paragraph>
            </Column>
            <Div
              flexDirection={['column', 'row']}
              justifyContent={['center']}
              alignItems={['center']}
            >
              <Column>
                <Button
                  onClick={() => navigate('/internal-proxy')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['170px']}
                  style={{
                    display: 'flex',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                    backgroundColor: '#ffffff',
                    marginBottom: 16,
                  }}
                >
                  proxy tools
                </Button>
              </Column>
              <Column>
                <Button
                  onClick={() => navigate('/dns-tools')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['170px']}
                  style={{
                    display: 'flex',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                    backgroundColor: '#ffffff',
                    marginBottom: 16,
                  }}
                >
                  dns tools
                </Button>
              </Column>
            </Div>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row
            flexDirection={['column']}
            css={header}
            mt={50}
            mb={50}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <H5>Looking Glass</H5>
              <Paragraph>Esta ferramenta provê informações de rede relativas ao roteamento de backbone da nossa infraestrutura. Devido à natureza destas requisições serem de baixa prioridade em nossos roteadores de rede, o serviço não é apropriado para medir SLA (Service Legal Agreements). O Looking Glass deve ser utilizado apenas para verificar informações de rota.</Paragraph>
            </Column>
            <Column
              mb={50}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiSelect
                  options={[
                    {label: 'Item A', value: 0},
                    {label: 'Item B', value: 1},
                    {label: 'Item C', value: 2}
                  ]}
                  label='Localização'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiSelect
                  options={[
                    {label: 'Item A', value: 0},
                    {label: 'Item B', value: 1},
                    {label: 'Item C', value: 2}
                  ]}
                  label='Tipo de teste'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiField
                  variant='outlined'
                  label='IP de Destino'
                  placeholder='198.49.64.128'
                  style={{
                    maxWidth: 328,
                    marginBottom: 30
                  }}
                />
                <Button
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['160px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  Run Test
                </Button>
              </div>
            </Column>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={[328, '100%', 680, 813]}
              style={{
                opacity: 0.5,
                margin: '0 auto'
              }}
            />
            <Column
              width={[1]}
              pt={40}
              pb={30}
            >
              <H5>Teste de Velocidade</H5>
              <Paragraph>Tenha certeza de que seus visitantes estão tendo o tempo de resposta mais rápido possível para o seu website hospedando-o em localizações estratégicas para seu público-alvo. Utilize o formulário abaixo para fazer o download de um arquivo de teste de qualquer localização em que temos data centers ou servidores.</Paragraph>
            </Column>
            <Column
              mb={50}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiSelect
                  options={[
                    {label: 'Item A', value: 0},
                    {label: 'Item B', value: 1},
                    {label: 'Item C', value: 2}
                  ]}
                  label='Localização'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiSelect
                  options={[
                    {label: 'Item A', value: 0},
                    {label: 'Item B', value: 1},
                    {label: 'Item C', value: 2}
                  ]}
                  label='Tamanho do arquivo'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiField
                  variant='outlined'
                  label='IP de Destino'
                  placeholder='198.49.64.128'
                  style={{
                    maxWidth: 328,
                    marginBottom: 30
                  }}
                />
                <Button
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['220px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  download file
                </Button>
              </div>
            </Column>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={[328, '100%', 680, 813]}
              style={{
                opacity: 0.5,
                margin: '0 auto'
              }}
            />
            <Column
              width={[1]}
              pt={40}
            >
              <H5>Meu IP</H5>
              <Paragraph>Veja aqui o seu IP de conexão com a internet que está atribuído ao seu computador ou dispositivo utilizado para acesso a internet.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row
            flexDirection={['column']}
            css={meuIp}
          >
            <Column
              width={[1]}
              mt={30}
              mb={50}
            >
              <H5>O seu endereço IP Atual é:</H5>
              <Div 
                css={btnMeuIp}
                style={{
                  margin: '0 auto'
                }}
              >
                <MeuIp />
              </Div>
              <Paragraph>Por vezes existem alterações nas zonas DNS e é necessário apagar a informação guardada de forma a obter os resultados atualizados corretamente. Para resolver esta situação, poderá simplesmente limpar o cache dos DNS, fazendo FlushDNS.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Ferramentas' />
      <PageTemplate
        title='Ferramentas'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
