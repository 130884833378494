import React from 'react'
import axios from 'axios'

class MeuIp extends React.Component {
  state = { value: 0 };
  meuip = async() => {
    const ip = await axios.get('https://internal-tools.hostdime.com.br/ip.php');
    console.log(ip);
    this.setState({ ip: ip.data });
  }

  componentDidMount () {
    this.meuip()
  };
  
  render() {
    return (
      <div>
        {this.state.ip}
      </div>
    )
  }
}

export default MeuIp
